@import url(//fonts.googleapis.com/css?family=Exo+2:700,400&subset=latin,cyrillic);

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important;
}

@media (max-width: 480px) {
    .visible-xs {
        display: block !important;
    }

    table.visible-xs {
        display: table;
    }

    tr.visible-xs {
        display: table-row !important;
    }

    th.visible-xs,
    td.visible-xs {
        display: table-cell !important;
    }
}

@media (max-width: 480px) {
    .visible-xs-block {
        display: block !important;
    }
}

@media (max-width: 480px) {
    .visible-xs-inline {
        display: inline !important;
    }
}

@media (max-width: 480px) {
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .visible-sm {
        display: block !important;
    }

    table.visible-sm {
        display: table;
    }

    tr.visible-sm {
        display: table-row !important;
    }

    th.visible-sm,
    td.visible-sm {
        display: table-cell !important;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .visible-sm-block {
        display: block !important;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .visible-sm-inline {
        display: inline !important;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .visible-sm-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 769px) and (max-width: 993px) {
    .visible-md {
        display: block !important;
    }

    table.visible-md {
        display: table;
    }

    tr.visible-md {
        display: table-row !important;
    }

    th.visible-md,
    td.visible-md {
        display: table-cell !important;
    }
}

@media (min-width: 769px) and (max-width: 993px) {
    .visible-md-block {
        display: block !important;
    }
}

@media (min-width: 769px) and (max-width: 993px) {
    .visible-md-inline {
        display: inline !important;
    }
}

@media (min-width: 769px) and (max-width: 993px) {
    .visible-md-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 994px) {
    .visible-lg {
        display: block !important;
    }

    table.visible-lg {
        display: table;
    }

    tr.visible-lg {
        display: table-row !important;
    }

    th.visible-lg,
    td.visible-lg {
        display: table-cell !important;
    }
}

@media (min-width: 994px) {
    .visible-lg-block {
        display: block !important;
    }
}

@media (min-width: 994px) {
    .visible-lg-inline {
        display: inline !important;
    }
}

@media (min-width: 994px) {
    .visible-lg-inline-block {
        display: inline-block !important;
    }
}

@media (max-width: 480px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 769px) and (max-width: 993px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 994px) {
    .hidden-lg {
        display: none !important;
    }
}

.visible-print {
    display: none !important;
}

@media print {
    .visible-print {
        display: block !important;
    }

    table.visible-print {
        display: table;
    }

    tr.visible-print {
        display: table-row !important;
    }

    th.visible-print,
    td.visible-print {
        display: table-cell !important;
    }
}

.visible-print-block {
    display: none !important;
}

@media print {
    .visible-print-block {
        display: block !important;
    }
}

.visible-print-inline {
    display: none !important;
}

@media print {
    .visible-print-inline {
        display: inline !important;
    }
}

.visible-print-inline-block {
    display: none !important;
}

@media print {
    .visible-print-inline-block {
        display: inline-block !important;
    }
}

@media print {
    .hidden-print {
        display: none !important;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}

.text-center-align {
    text-align: center;
}

@media (min-width: 769px) {
    .ad-top-fixedHeight {
        min-height: 102px;
    }
}

.GoogleMapsImgOverride {
    max-width: 100% !important;
}

.bcPlayer {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

    .bcPlayer iframe,
    .bcPlayer object,
    .bcPlayer embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

.brightcove-video-container .vjs-modal-dialog-content .vjs-social-share-links a {
    font-family: VideoJS;
}

.video-in-modal-box {
    max-width: 640px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    background-color: black !important;
    color: white;
}

    .video-in-modal-box .video-title {
        margin-top: -27px;
        color: #d9d9d9;
        margin-bottom: 0px;
        margin-left: 30px;
        margin-right: 30px;
    }

    .video-in-modal-box .remodal-close {
        border: 2px solid white !important;
    }

        .video-in-modal-box .remodal-close:after {
            color: white !important;
        }

.spriteV2 {
    background-image: url("../../img/spritesheetV10.png");
    background-repeat: no-repeat;
    display: block;
    /**
        --NOTICE--
        THE BELOW CODE WAS ALREADY MANUALLY EDITED THEREFORE IT WILL HAVE TO BE CONTINUOUSLY MANUALLY EDITED UNTIL THE GARBAGE IS REMOVED
        --NOTICE--
         -- NOTICE --
        ONLY REPLACE THE BOTTOM STYLES. 
        THE NAME OF THE STYLE is name of the file. Do not Manually Edit the Below. 
        This should be auto generated by http://draeton.github.io/stitches/
        USE the images in folder /img/raw-images/
        Place the images in raw-images folder then go to the site and upload all images in the raw-images folder 
        then generate a compact image, take the LESS for sprites
        and paste it below 
        and save the PNG generate into /img/spritesheet.png
        -- NOTICE --
    **/
}

.sprite-badge_4dx {
    width: 220px;
    height: 29px;
    background-position: -5px -5px;
}

.sprite-badge_4dx_3d {
    width: 220px;
    height: 30px;
    background-position: -235px -5px;
}

.sprite-badge_4dx_3d_fr {
    width: 220px;
    height: 30px;
    background-position: -465px -5px;
}

.sprite-badge_4dx_fr {
    width: 220px;
    height: 29px;
    background-position: -5px -45px;
}

.sprite-badge_Barco {
    width: 220px;
    height: 30px;
    background-position: -235px -45px;
}

.sprite-badge_Barco_fr {
    width: 220px;
    height: 30px;
    background-position: -465px -45px;
}

.sprite-badge_full_recliner_seating {
    width: 220px;
    height: 30px;
    background-position: -5px -85px;
}

.sprite-badge_full_recliner_seating_3d {
    width: 220px;
    height: 30px;
    background-position: -235px -85px;
}

.sprite-badge_full_recliner_seating_3d_fr {
    width: 220px;
    height: 30px;
    background-position: -465px -85px;
}

.sprite-badge_full_recliner_seating_fr {
    width: 220px;
    height: 30px;
    background-position: -5px -125px;
}

.sprite-badge_imax {
    width: 220px;
    height: 30px;
    background-position: -235px -125px;
}

.sprite-badge_imax_3d {
    width: 220px;
    height: 30px;
    background-position: -465px -125px;
}

.sprite-badge_imax_3d_fr {
    width: 220px;
    height: 30px;
    background-position: -5px -165px;
}

.sprite-badge_imax_fr {
    width: 220px;
    height: 30px;
    background-position: -235px -165px;
}

.sprite-badge_sensory_friendly {
    width: 220px;
    height: 30px;
    background-position: -465px -165px;
}

.sprite-badge_sensory_friendly_fr {
    width: 220px;
    height: 30px;
    background-position: -5px -205px;
}

.sprite-badge_wg_event_pass {
    width: 220px;
    height: 30px;
    background-position: -235px -205px;
}

.sprite-badge_wg_event_pass_fr {
    width: 220px;
    height: 30px;
    background-position: -465px -205px;
}

.sprite-badge_wg_fan_exp {
    width: 220px;
    height: 30px;
    background-position: -5px -245px;
}

.sprite-badge_wg_fan_exp_fr {
    width: 220px;
    height: 30px;
    background-position: -235px -245px;
}

.sprite-clubhouse {
    width: 220px;
    height: 30px;
    background-position: -694px -805px;
}

.sprite-screenx {
    width: 220px;
    height: 31px;
    background-position: -695px -844px;
}

.sprite-dbox {
    width: 220px;
    height: 30px;
    background-position: -465px -245px;
}

.sprite-dbox_3d {
    width: 220px;
    height: 30px;
    background-position: -5px -285px;
}

.sprite-dbox_3d_fr {
    width: 220px;
    height: 30px;
    background-position: -235px -285px;
}

.sprite-dbox_fr {
    width: 220px;
    height: 30px;
    background-position: -465px -285px;
}

.sprite-dbox_recl {
    width: 220px;
    height: 30px;
    background-position: -5px -325px;
}

.sprite-dbox_recl_3d {
    width: 220px;
    height: 30px;
    background-position: -235px -325px;
}

.sprite-dbox_recl_3d_fr {
    width: 220px;
    height: 30px;
    background-position: -465px -325px;
}

.sprite-dbox_recl_fr {
    width: 220px;
    height: 30px;
    background-position: -5px -365px;
}

.sprite-dbox_ultraavx_recl {
    width: 220px;
    height: 30px;
    background-position: -235px -365px;
}

.sprite-dbox_ultraavx_recl_3d {
    width: 220px;
    height: 30px;
    background-position: -465px -365px;
}

.sprite-dbox_ultraavx_recl_3d_atmos {
    width: 220px;
    height: 30px;
    background-position: -5px -405px;
}

.sprite-dbox_ultraavx_recl_3d_atmos_fr {
    width: 220px;
    height: 30px;
    background-position: -235px -405px;
}

.sprite-dbox_ultraavx_recl_3d_fr {
    width: 220px;
    height: 30px;
    background-position: -465px -405px;
}

.sprite-dbox_ultraavx_recl_atmos {
    width: 220px;
    height: 30px;
    background-position: -5px -445px;
}

.sprite-dbox_ultraavx_recl_atmos_fr {
    width: 220px;
    height: 30px;
    background-position: -235px -445px;
}

.sprite-dbox_ultraavx_recl_fr {
    width: 220px;
    height: 30px;
    background-position: -465px -445px;
}

.sprite-format_3d {
    width: 28px;
    height: 19px;
    background-position: -695px -5px;
}

.sprite-format_atmos {
    width: 70px;
    height: 19px;
    background-position: -733px -5px;
}

.sprite-format_dbox {
    width: 74px;
    height: 19px;
    background-position: -813px -5px;
}

.sprite-format_dolbyatmos {
    width: 114px;
    height: 19px;
    background-position: -695px -34px;
}

.sprite-format_imax2 {
    width: 69px;
    height: 19px;
    background-position: -819px -34px;
}

.sprite-format_imax_vr {
    width: 120px;
    height: 19px;
    background-position: -695px -63px;
}

.sprite-format_reald3d {
    width: 144px;
    height: 19px;
    background-position: -695px -92px;
}

.sprite-format_recl {
    width: 53px;
    height: 19px;
    background-position: -825px -63px;
}

.sprite-format_recl_fr {
    width: 65px;
    height: 19px;
    background-position: -695px -121px;
}

.sprite-format_selectseats {
    width: 36px;
    height: 19px;
    background-position: -849px -92px;
}

.sprite-format_selectseats2 {
    width: 36px;
    height: 19px;
    background-position: -770px -121px;
}

.sprite-format_selectseats_fr {
    width: 45px;
    height: 19px;
    background-position: -816px -121px;
}

.sprite-format_ultraAVX {
    width: 67px;
    height: 19px;
    background-position: -695px -150px;
}

.sprite-format_vip {
    width: 36px;
    height: 19px;
    background-position: -772px -150px;
}

.sprite-format_clubhouse {
    width: 44px;
    height: 15px;
    background-position: -695px -178px;
}

.sprite-format_screenx {
    width: 44px;
    height: 15px;
    background-position: -695px -178px;
}

.sprite-reald3D {
    width: 60px;
    height: 19px;
    background-position: -818px -150px;
}

.sprite-regular {
    width: 220px;
    height: 30px;
    background-position: -5px -485px;
}

.sprite-regular_3d {
    width: 220px;
    height: 30px;
    background-position: -235px -485px;
}

.sprite-regular_3d_fr {
    width: 220px;
    height: 30px;
    background-position: -465px -485px;
}

.sprite-regular_fr {
    width: 220px;
    height: 30px;
    background-position: -5px -525px;
}

.sprite-selectseats {
    width: 220px;
    height: 30px;
    background-position: -235px -525px;
}

.sprite-selectseats_3d {
    width: 220px;
    height: 30px;
    background-position: -465px -525px;
}

.sprite-selectseats_3d_fr {
    width: 220px;
    height: 30px;
    background-position: -5px -565px;
}

.sprite-selectseats_fr {
    width: 220px;
    height: 30px;
    background-position: -235px -565px;
}

.sprite-starsandstrollers {
    width: 220px;
    height: 30px;
    background-position: -465px -565px;
}

.sprite-starsandstrollers_fr {
    width: 220px;
    height: 30px;
    background-position: -5px -605px;
}

.sprite-traditional {
    width: 220px;
    height: 30px;
    background-position: -235px -605px;
}

.sprite-traditional_3d {
    width: 220px;
    height: 30px;
    background-position: -465px -605px;
}

.sprite-traditional_3d_fr {
    width: 220px;
    height: 30px;
    background-position: -5px -645px;
}

.sprite-traditional_fr {
    width: 220px;
    height: 30px;
    background-position: -235px -645px;
}

.sprite-ultraavx {
    width: 220px;
    height: 30px;
    background-position: -465px -645px;
}

.sprite-ultraavx_3d {
    width: 220px;
    height: 30px;
    background-position: -5px -685px;
}

.sprite-ultraavx_3d_atmos {
    width: 220px;
    height: 30px;
    background-position: -235px -685px;
}

.sprite-ultraavx_3d_atmos_dbox {
    width: 220px;
    height: 30px;
    background-position: -465px -685px;
}

.sprite-ultraavx_3d_atmos_dbox_fr {
    width: 220px;
    height: 30px;
    background-position: -5px -725px;
}

.sprite-ultraavx_3d_atmos_fr {
    width: 220px;
    height: 30px;
    background-position: -235px -725px;
}

.sprite-ultraavx_3d_dbox {
    width: 220px;
    height: 30px;
    background-position: -465px -725px;
}

.sprite-ultraavx_3d_dbox_fr {
    width: 220px;
    height: 30px;
    background-position: -5px -765px;
}

.sprite-ultraavx_3d_fr {
    width: 220px;
    height: 30px;
    background-position: -235px -765px;
}

.sprite-ultraavx_atmos {
    width: 220px;
    height: 30px;
    background-position: -465px -765px;
}

.sprite-ultraavx_atmos_dbox {
    width: 220px;
    height: 30px;
    background-position: -5px -805px;
}

.sprite-ultraavx_atmos_dbox_fr {
    width: 220px;
    height: 30px;
    background-position: -235px -805px;
}

.sprite-ultraavx_atmos_fr {
    width: 220px;
    height: 30px;
    background-position: -465px -805px;
}

.sprite-ultraavx_dbox {
    width: 220px;
    height: 30px;
    background-position: -5px -845px;
}

.sprite-ultraavx_dbox_fr {
    width: 220px;
    height: 30px;
    background-position: -235px -845px;
}

.sprite-ultraavx_fr {
    width: 220px;
    height: 30px;
    background-position: -465px -845px;
}

.sprite-ultraavx_recl {
    width: 220px;
    height: 30px;
    background-position: -898px -5px;
}

.sprite-ultraavx_recl_3d {
    width: 220px;
    height: 30px;
    background-position: -898px -45px;
}

.sprite-ultraavx_recl_3d_atmos {
    width: 220px;
    height: 30px;
    background-position: -895px -85px;
}

.sprite-ultraavx_recl_3d_atmos_fr {
    width: 220px;
    height: 30px;
    background-position: -888px -125px;
}

.sprite-ultraavx_recl_3d_fr {
    width: 220px;
    height: 30px;
    background-position: -888px -165px;
}

.sprite-ultraavx_recl_atmos {
    width: 220px;
    height: 30px;
    background-position: -695px -205px;
}

.sprite-ultraavx_recl_atmos_fr {
    width: 220px;
    height: 30px;
    background-position: -695px -245px;
}

.sprite-ultraavx_recl_fr {
    width: 220px;
    height: 30px;
    background-position: -695px -285px;
}

.sprite-vip18plus {
    width: 220px;
    height: 30px;
    background-position: -695px -325px;
}

.sprite-vip18plus_3d {
    width: 220px;
    height: 30px;
    background-position: -695px -365px;
}

.sprite-vip18plus_3d_fr {
    width: 220px;
    height: 30px;
    background-position: -695px -405px;
}

.sprite-vip18plus_fr {
    width: 220px;
    height: 30px;
    background-position: -695px -445px;
}

.sprite-vip19plus {
    width: 220px;
    height: 30px;
    background-position: -695px -485px;
}

.sprite-vip19plus_3d {
    width: 220px;
    height: 30px;
    background-position: -695px -525px;
}

.sprite-vip19plus_3d_fr {
    width: 220px;
    height: 30px;
    background-position: -695px -565px;
}

.sprite-vip19plus_3d_frs {
    width: 220px;
    height: 30px;
    background-position: -695px -605px;
}

.sprite-vip19plus_3d_frs_fr {
    width: 220px;
    height: 30px;
    background-position: -695px -645px;
}

.sprite-vip19plus_fr {
    width: 220px;
    height: 30px;
    background-position: -695px -685px;
}

.sprite-vip19plus_frs {
    width: 220px;
    height: 30px;
    background-position: -695px -725px;
}

.sprite-vip19plus_frs_fr {
    width: 220px;
    height: 30px;
    background-position: -695px -765px;
}

@font-face {
    font-family: 'cineplex-custom-icon';
    src: url('../../fonts/cineplex-custom-icon.eot?-m1fgam');
    src: url('../../fonts/cineplex-custom-icon.eot?#iefix-m1fgam') format('embedded-opentype'), url('../../fonts/cineplex-custom-icon.woff?-m1fgam') format('woff'), url('../../fonts/cineplex-custom-icon.ttf?-m1fgam') format('truetype'), url('../../fonts/cineplex-custom-icon.svg?-m1fgam#cineplex-custom-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="iconV2-"],
[class*=" iconV2-"] {
    font-family: 'cineplex-custom-icon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.iconV2-descriptive-video:before {
    content: "\e601";
}

.iconV2-valet-parking:before {
    content: "\61";
}

.iconV2-theatre-seat:before {
    content: "\62";
}

.iconV2-hearing-assistance:before {
    content: "\e600";
}

#tooltip {
    padding: 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6) 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6) 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6) 1px 2px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    display: none;
    position: absolute;
    z-index: 9999999999;
}

    #tooltip.black {
        background: rgba(0, 0, 0, 0.95);
        background: #000;
        color: #fff;
    }

        #tooltip.black.top .arrow {
            background-position: -6px -17px;
        }

        #tooltip.black.right .arrow {
            background-position: 5px -6px;
        }

        #tooltip.black.bottom .arrow {
            background-position: -6px 5px;
        }

        #tooltip.black.left .arrow {
            background-position: -17px -6px;
        }

    #tooltip.white {
        background: rgba(255, 255, 255, 0.95);
        background: #FFF;
        color: #222;
    }

        #tooltip.white.top .arrow {
            background-position: -39px -17px;
        }

        #tooltip.white.right .arrow {
            background-position: -28px -6px;
        }

        #tooltip.white.bottom .arrow {
            background-position: -39px 5px;
        }

        #tooltip.white.left .arrow {
            background-position: -50px -6px;
        }

    #tooltip .arrow {
        width: 11px;
        height: 11px;
        position: absolute;
        background-image: url('../../img/tooltip.png');
        background-repeat: no-repeat;
    }

@media print {
    #tooltip {
        display: none !important;
    }
}

#pquizBody #pquizQuestions .box {
    margin-right: 6px;
    margin-left: 6px;
}

#pquizBody #pquizQuestions .pquizChoice .box:hover {
    background-color: #e7a614;
}

#pquizBody #pquizQuestions .pquizChoice .box--body {
    text-align: center;
    padding: 0px !important;
    cursor: pointer;
}

    #pquizBody #pquizQuestions .pquizChoice .box--body img {
        margin-left: auto;
        margin-right: auto;
    }

#pquizBody #pquizQuestions .highlightedImage .box {
    border: 1px solid #e7a614;
}

#pquizBody #pquizQuestions .NoQuestionImage {
    border: none;
    box-shadow: none;
}
/* ==========================================================================
   Remodal necessary styles
   ========================================================================== */
/* Hide scroll bar */
html.remodal_lock,
body.remodal_lock {
    overflow: hidden;
}
/* Anti FOUC */
.remodal,
[data-remodal-id] {
    visibility: hidden;
}
/* Overlay necessary styles */
.remodal-overlay {
    position: fixed;
    z-index: 999999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    overflow: auto;
    text-align: center;
    -webkit-overflow-scrolling: touch;
}

    .remodal-overlay:after {
        display: inline-block;
        height: 100%;
        margin-left: -0.05em;
        content: "";
    }
/* Fix iPad, iPhone glitches */
.remodal-overlay {
    -webkit-backface-visibility: hidden;
}
/* Modal dialog necessary styles */
.remodal {
    position: relative;
    display: inline-block;
}
    /* Remodal anti-zoom. Font-size should be >= 16px. */
    .remodal select,
    .remodal textarea,
    .remodal input,
    .remodal select:focus,
    .remodal textarea:focus,
    .remodal input:focus {
        font-size: 16px;
    }
    /* ==========================================================================
   Remodal default theme
   ========================================================================== */
    /* Default theme font */
    .remodal,
    .remodal * {
        font-family: "Exo 2", sans-serif;
    }
/* Background for effects */
.remodal-bg {
    -webkit-transition: -webkit-filter 0.1s linear;
    -moz-transition: -moz-filter 0.1s linear;
    -o-transition: -o-filter 0.1s linear;
    transition: filter 0.1s linear;
}
/* Overlay default theme styles */
.remodal-overlay {
    -webkit-transition: opacity 0.1s linear;
    -moz-transition: opacity 0.1s linear;
    -o-transition: opacity 0.1s linear;
    transition: opacity 0.1s linear;
    opacity: 0;
    background: rgba(33, 36, 46, 0.95);
}

body.remodal_active .remodal-overlay {
    opacity: 1;
}
/* Modal dialog default theme styles */
.remodal {
    font-size: 16px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    padding: 35px;
    padding: 35px 15px 15px;
    -webkit-transition: -webkit-transform 0.1s linear;
    -moz-transition: -moz-transform 0.1s linear;
    -o-transition: -o-transform 0.1s linear;
    transition: transform 0.1s linear;
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
    color: #182a3c;
    background: #f4f4f4;
    background-clip: padding-box;
    -webkit-box-shadow: 0 0 8px #171a24;
    box-shadow: 0 0 8px #171a24;
}

body.remodal_active .remodal {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}
/* Modal dialog vertical align  */
.remodal,
.remodal-overlay:after {
    vertical-align: middle;
}
/* Close button */
.remodal-close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 28px;
    height: 28px;
    -webkit-transition: background 0.1s linear;
    -moz-transition: background 0.1s linear;
    -o-transition: background 0.1s linear;
    transition: background 0.1s linear;
    text-decoration: none;
    border: 2px solid #3e5368;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

    .remodal-close:after {
        font-family: Arial, "Helvetica CY", "Nimbus Sans L", sans-serif !important;
        font-size: 28px;
        line-height: 25px;
        display: block;
        content: "×";
        cursor: pointer;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -o-transition: all 0.1s linear;
        transition: all 0.1s linear;
        text-decoration: none;
        color: #3e5368;
    }

    .remodal-close:hover,
    .remodal-close:active {
        background: #3e5368;
    }

        .remodal-close:hover.remodal-close:after,
        .remodal-close:active.remodal-close:after {
            color: #f4f4f4;
        }
/* Dialog buttons */
.remodal-confirm,
.remodal-cancel {
    font-size: 10pt;
    display: inline-block;
    width: 120px;
    margin: 0 0 5px 0;
    padding: 9px 0;
    cursor: pointer;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
    text-align: center;
    text-decoration: none;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    background-clip: padding-box;
}

.remodal-confirm {
    color: #16a085;
    border: 2px solid #16a085;
    background: #f4f4f4;
}

    .remodal-confirm:hover,
    .remodal-confirm:active {
        color: #f4f4f4;
        background: #16a085;
    }

.remodal-cancel {
    color: #c0392b;
    border: 2px solid #c0392b;
    background: #f4f4f4;
}

    .remodal-cancel:hover,
    .remodal-cancel:active {
        color: #f4f4f4;
        background: #c0392b;
    }
/* Media queries
   ========================================================================== */
/* min-width 641px */
@media only screen and (min-width: 40.063em) {
    .remodal {
        max-width: 700px;
        min-height: 0;
        margin: 20px auto;
        -webkit-border-radius: 6px;
        border-radius: 6px;
    }
}
/* IE8
   ========================================================================== */
html.lt-ie9,
html.lt-ie9 body {
    overflow: auto !important;
    min-height: 100%;
    margin: 0;
}

.lt-ie9 .remodal-overlay {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAAA3NCSVQICAjb4U/gAAAABlBMVEX///8AAABVwtN+AAAAAnRSTlMAuyogpzwAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAGHRFWHRDcmVhdGlvbiBUaW1lADA2LzEwLzIwMTSCx1nsAAAAD0lEQVQImWP4//8DAxUxACnDOpkfX95WAAAAAElFTkSuQmCC);
}

.lt-ie9 .remodal-close:after {
    content: "";
}

.lt-ie9 .remodal {
    width: 500px;
    min-height: auto;
}

#responsive_recaptcha {
    position: relative;
}

    #responsive_recaptcha img,
    #responsive_recaptcha #recaptcha_image {
        -webkit-border-top-right-radius: 0.5em;
        -webkit-border-bottom-right-radius: 0.5em;
        -webkit-border-bottom-left-radius: 0.5em;
        -webkit-border-top-left-radius: 0.5em;
        -moz-border-radius-topright: 0.5em;
        -moz-border-radius-bottomright: 0.5em;
        -moz-border-radius-bottomleft: 0.5em;
        -moz-border-radius-topleft: 0.5em;
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
        border-top-left-radius: 0.5em;
        -moz-background-clip: padding-box;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        padding-bottom: 50px;
    }

    #responsive_recaptcha #recaptcha_response_field {
        margin-bottom: 5px;
    }

    #responsive_recaptcha .options-container .option-text {
        float: left;
        overflow: hidden;
    }

    #responsive_recaptcha .options-container .options {
        float: right;
        list-style: none;
        height: 18px;
        display: inline-block;
    }

        #responsive_recaptcha .options-container .options li {
            float: left;
            margin: 0 15px 0 0;
        }

            #responsive_recaptcha .options-container .options li a {
                text-decoration: none;
                font-size: 16px;
                color: #444444;
                display: block;
                width: 20px;
                height: 18px;
            }

                #responsive_recaptcha .options-container .options li a .fa {
                    font-size: 1.5em;
                }

                #responsive_recaptcha .options-container .options li a:active {
                    position: relative;
                    top: 1px;
                    text-shadow: none;
                }

    #responsive_recaptcha #recaptcha_audio_play_again,
    #responsive_recaptcha #recaptcha_audio_download {
        cursor: pointer;
        display: inline;
        text-transform: uppercase;
        line-height: 1;
        font-family: "proxima-nova", "Helvetica Neue", helvetica, arial, sans-serif;
        font-weight: 700;
    }

    #responsive_recaptcha .captcha_hide {
        display: none;
    }

.visible-dropdown-tab {
    opacity: 1 !important;
    visibility: visible !important;
}

    .visible-dropdown-tab a.sub-nav-item:hover {
        color: #e7a614;
    }

.dropdown-mobile.menu-item .mobile-menu-text {
    font-size: 16px;
}

#SearchModuleV2 .autocomplete--results {
    z-index: -1;
}

li .menu-item-bolded {
    background: #447ea3;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #2a4e6b), color-stop(1, #366485));
    background: -ms-linear-gradient(bottom, #2a4e6b, #366485);
    background: -moz-linear-gradient(center bottom, #2a4e6b 0%, #366485 100%);
    background: -o-linear-gradient(#366485, #2a4e6b);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#366485', endColorstr='#2a4e6b', GradientType=0);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
    border-bottom: 1px solid #000000;
}

    li .menu-item-bolded a {
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.9);
    }

        li .menu-item-bolded a:active {
            background: none;
        }

    li .menu-item-bolded:hover,
    li .menu-item-bolded:focus {
        color: #fff !important;
        background: #285e84;
        background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #193b53), color-stop(1, #1f4a69));
        background: -ms-linear-gradient(bottom, #193b53, #1f4a69);
        background: -moz-linear-gradient(center bottom, #193b53 0%, #1f4a69 100%);
        background: -o-linear-gradient(#1f4a69, #193b53);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1f4a69', endColorstr='#193b53', GradientType=0);
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
        outline: 0 none;
    }

    li .menu-item-bolded:active {
        background: #193b53;
        background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #193b53), color-stop(1, #193b53));
        background: -ms-linear-gradient(bottom, #193b53, #193b53);
        background: -moz-linear-gradient(center bottom, #193b53 0%, #193b53 100%);
        background: -o-linear-gradient(#193b53, #193b53);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#193b53', endColorstr='#193b53', GradientType=0);
    }

#SearchField {
    z-index: 94;
}

    #SearchField .icon-search {
        font-size: 1em;
        position: absolute;
        z-index: 100;
        color: #666666;
        float: left;
        top: 20px;
        left: 9px;
    }

#SearchModuleV2 {
    position: relative;
    z-index: 300;
    margin-top: 24px;
    float: right;
    width: 320px;
}

    #SearchModuleV2 .search-submit-V2 {
        display: none;
        top: 5px;
        float: right;
        position: absolute;
        right: 3px;
        z-index: 100;
    }

    #SearchModuleV2 .search-input {
        -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25), 0 1px 0 rgba(255, 255, 255, 0.1);
        -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25), 0 1px 0 rgba(255, 255, 255, 0.1);
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25), 0 1px 0 rgba(255, 255, 255, 0.1);
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        color: #000;
        background-color: #f4f4f4;
        width: 100%;
        height: 35px;
        padding: 8px 40px 5px;
    }

#SearchModuleV2 .search-input:focus ~ .search-submit-V2,
#SearchModuleV2 .search-submit-V2:focus,
#SearchModuleV2 .search-submit-V2:hover {
    display: block;
}

@media (min-width: 769px) {
    .portal-nav {
        display: block;
    }
}

@media (max-width: 769px) and (min-width: 481px) {
    #SearchField .icon-search {
        top: 13px;
    }
}

@media (max-width: 481px) {
    .dropdown-mobile.menu-item .mobile-menu-text {
        font-size: 12px;
    }

    .logo {
        margin: 0px;
    }

    #SearchModuleV2 {
        float: right;
        width: 59%;
        margin: 0px;
    }

        #SearchModuleV2 #SearchField .icon-search {
            top: 11px;
        }

        #SearchModuleV2 #SearchField .search-input {
            font-size: 12px;
        }

        #SearchModuleV2 #SearchField .search-submit-V2 {
            font-size: 10px;
        }

    .menu-item > a {
        padding: 12px;
    }

        .menu-item > a .icon-menu {
            font-size: 11px;
        }
}

@media (max-width: 924px) {
    .menu-item > a {
        padding: 16px 14px 16px 14px;
    }

    .menu-item > a {
        font-size: 14px;
    }

    .portal-nav,
    .lang-toggle {
        display: none;
    }
}

.theatreDetailsV2 .formats i {
    vertical-align: middle;
    display: inline-block;
}

.theatreDetailsV2 .accessibility li {
    float: left;
    padding: 10px;
    font-size: 32px;
}

.theatreDetailsV2 .fineprint {
    text-align: justify;
    margin-bottom: 5px;
}

.theatre-contact .btn-phone-middle {
    height: 51px;
    padding-top: 16px;
}

.theatre-contact .btn-back-text {
    height: 51px;
    white-space: normal;
}

.theatre-infoV2 {
    /*padding-left: 24px;*/
    position: relative;
    display: contents;
}

    .theatre-infoV2 .theatre-logoV2,
    .theatre-infoV2 .theatre-logoV2 a,
    .theatre-infoV2 .theatre-logoV2 img {
        margin-right: auto;
        margin-left: auto;
    }

    .theatre-infoV2 i {
        color: #cccccc;
    }

.theatre-detailsV2 {
    padding: 0px 10px 0px 10px !important;
}

    .theatre-detailsV2 .theatre-name {
        margin-top: 10px;
        display: block;
    }

        .theatre-detailsV2 .theatre-name .theatre-text {
            overflow: hidden;
            padding-left: 5px;
            color: #292929;
        }

    .theatre-detailsV2 .theatre-favourite {
        float: left;
        cursor: pointer;
    }

        .theatre-detailsV2 .theatre-favourite i {
            border: solid 1px #d9d9d9;
            padding: 2px;
            -webkit-border-top-right-radius: 5px;
            -webkit-border-bottom-right-radius: 5px;
            -webkit-border-bottom-left-radius: 5px;
            -webkit-border-top-left-radius: 5px;
            -moz-border-radius-topright: 5px;
            -moz-border-radius-bottomright: 5px;
            -moz-border-radius-bottomleft: 5px;
            -moz-border-radius-topleft: 5px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            -moz-background-clip: padding-box;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
        }

            .theatre-detailsV2 .theatre-favourite i.favourite {
                border: solid 1px #e7a614;
            }

    .theatre-detailsV2 .theatre-info--sub p {
        line-height: normal;
        margin-bottom: .5em;
    }

    .theatre-detailsV2 .theatre-info--sub .formats i {
        vertical-align: middle;
        display: inline-block;
    }

    .theatre-detailsV2 .theatre-info--sub .theatre-info--content {
        display: inline-block;
        padding-right: 5px;
    }

    .theatre-detailsV2 .theatre-info--sub .theatre-ammenities {
        display: block;
    }

    .formats a {
        display: inline-block;
        vertical-align: middle;
    }

    .formats img {
        max-height: 18px;
    }

    .formats span {
        font-size: 1.5em;
        vertical-align: baseline;
        padding: 0px 5px;
    }

    .theatre-detailsV2 .theatre-info--sub .theatre-address {
        display: block;
    }

.theatre-mapV2 img {
    padding: 0 10px 0px 10px;
    max-width: 100% !important;
}

.theatre-mapV2 .theatre-icons {
    text-align: right;
    margin-bottom: 0px;
    margin-right: 8px;
    padding-top: 5px;
}

    .theatre-mapV2 .theatre-icons i {
        margin-left: 3px;
        margin-right: 3px;
    }

        .theatre-mapV2 .theatre-icons i:hover {
            color: #2c3e4c;
        }

.theatre-mapV2 .theatre-distance {
    margin-bottom: 0px;
    margin-top: 5px;
}

.theatre-mapV2 a.theatre-map-modal {
    display: block;
}

.show-showtimesV2 {
    display: block;
    text-align: center;
    padding: 8px 0px 10px 0px !important;
}

    .show-showtimesV2 .btn-primary {
        padding: 8px 3px 8px 8px;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -webkit-border-top-left-radius: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        -moz-border-radius-bottomleft: 5px;
        -moz-border-radius-topleft: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        -moz-background-clip: padding-box;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        width: 100% !important;
    }

@media (max-width: 481px) {
    .theatre-infoV2 {
        padding: 0px 0px 0px 10px !important;
    }

    .theatre-detailsV2 {
        padding: 0px 10px 0px 0px !important;
    }

        .theatre-detailsV2 .theatre-info--sub {
            padding-left: 5px;
        }

        .theatre-detailsV2 .theatre-favourite {
            display: inline-block;
            margin-top: 2px;
        }

        .theatre-detailsV2 .theatre-name {
            padding-left: 5px;
        }

        .theatre-detailsV2 .theatre-name .theatre-text {
            padding-left: 5px;
        }

    .theatre-icons i {
        float: left;
        position: absolute;
    }

        .theatre-icons i.icon-email {
            margin-left: -15px;
        }

        .theatre-icons i.icon-print {
            margin-left: 0px;
        }

    .show-showtimesV2 {
        padding: 8px 5px 10px 0px !important;
    }
}

@media (min-width: 925px) {
    .theatre-detailsV2 .theatre-info--sub .theatre-info--content.desk-show-nav,
    .theatre-logoV2 .desk-show-nav {
        display: none;
    }
}

@media (max-width: 924px) {
    .desk-only-nav {
        display: none !important;
    }

    .theatre-detailsV2 .theatre-info--sub .theatre-info--content.desk-show-nav {
        display: inline-block;
    }
}
/* we need the grid three-fifths to act as four-fifths between nav and medium screens (this is where we hide the map) for theatre header details */
@media (max-width: 924px) and (min-width: 480px) {
    .theatre-detailsV2 {
        width: 100%;
    }

    .show-showtimesV2 {
        text-align: left;
    }
}

.showtime-theatre:hover,
.showtime-theatre:active {
    border: solid 1px #e7a614;
}

#specialty-theatres .autocomplete--results li.favourite a:before {
    color: #e7a614;
    content: "\e005";
    font-family: 'icomoon';
}

.showtimes-page .fineprint {
    text-align: justify;
    margin-bottom: 20px;
}

@media print {
    .showtimes-page .fineprint {
        page-break-before: always;
    }
}

.layout-showtimes #hero {
    padding: 32px 24px 10px !important;
}

.layout-showtimes .title-left {
    max-width: 100%;
    margin-bottom: 10px;
}

#advancedSearchGrid {
    padding-left: 10px;
}

    #advancedSearchGrid .advancedSearchWrapper {
        position: absolute;
        right: 0vw;
        margin-top: 60px;
        transition: .4s ease;
    }

    #advancedSearchGrid .advancedSearchWrapper.expanded {
        margin-top: 165px;
    }

    @media (min-width: 769px) {
        #advancedSearchGrid .advancedSearchWrapper.expanded {
            margin-top: 115px;
        }
    }

    #advancedSearchGrid #showtimeRefineBar {
        padding-top: 8px;
        padding-right: 10px;
    }

        #advancedSearchGrid #showtimeRefineBar select {
            width: 100%;
            padding-right: 25px;
            margin-bottom: 8px;
        }

        #advancedSearchGrid #showtimeRefineBar .FilterTimeSwitcher {
            text-align: center;
        }

            #advancedSearchGrid #showtimeRefineBar .FilterTimeSwitcher ul.switcher > li {
                padding: 5px 5px;
            }

@media (max-width: 924px) and (min-width: 769px) {
    #ShowtimesWidget .submit-wrap {
        border: solid 1px #e5e5e5;
        margin-left: -10px;
        padding-bottom: 7px;
        width: 21%;
    }
}

@media (min-width: 769px) {
    #showtimeRefineBar .grid__item {
        padding-left: 10px;
    }

        #showtimeRefineBar .grid__item:first-child {
            padding-left: 24px;
        }
}

@media (max-width: 769px) {
    #advancedSearchGrid {
        padding-top: 10px;
    }
}

@media (max-width: 481px) {
    #ShowtimesWidget .widget-item {
        border-right: 1px solid #e5e5e5;
    }
}

.showtimes-widget-wrap h3 {
    margin-bottom: 0px;
}

.showtimes-widget-wrap #ShowtimesWidget {
    margin: 5px 0px;
}

    .showtimes-widget-wrap #ShowtimesWidget .widget-item {
        border: solid 1px #e5e5e5;
    }

    .showtimes-widget-wrap #ShowtimesWidget .showtime-input-wrap {
        padding-right: 30px;
        margin-right: 0px;
    }

        .showtimes-widget-wrap #ShowtimesWidget .showtime-input-wrap .showtime--input-clear {
            float: right;
            font-size: 17px;
            right: 15px;
            color: #111;
            display: block;
            height: 12px;
            margin: 0;
            margin-top: -10px;
            overflow: visible;
            position: absolute;
            top: 50%;
            z-index: 50;
            width: 10px;
        }

        .showtimes-widget-wrap #ShowtimesWidget .showtime-input-wrap input {
            padding-right: 0px;
            margin-right: 0px;
        }

    .showtimes-widget-wrap #ShowtimesWidget .autocomplete--results-active label,
    .showtimes-widget-wrap #ShowtimesWidget .autocomplete--results-active input[type="text"],
    .showtimes-widget-wrap #ShowtimesWidget .autocomplete--results-active .showtime--input-clear {
        z-index: 556;
    }

.showtimes-widget-wrap .ComingSoonNowPlaying {
    margin-top: 5px;
}

    .showtimes-widget-wrap .ComingSoonNowPlaying a {
        color: #2d6892;
        text-transform: uppercase;
        line-height: 1;
        font-family: "proxima-nova", "Helvetica Neue", helvetica, arial, sans-serif;
        font-weight: 700;
    }

        .showtimes-widget-wrap .ComingSoonNowPlaying a:hover .icon-arrow-3-right {
            position: relative;
            right: -3px;
        }

    .showtimes-widget-wrap .ComingSoonNowPlaying .icon-arrow-3-right {
        font-size: .78571em;
        -webkit-transition: all 0.1s ease-out;
        -moz-transition: all 0.1s ease-out;
        -o-transition: all 0.1s ease-out;
        transition: all 0.1s ease-out;
    }

.btn-showtime {
    width: auto !important;
}

.seatMapLink,
.seatMapDisabled {
    float: left;
    background-color: #FFF;
    padding: 3px;
    margin: 0px 0px 0px -1px;
    height: 28px !important;
}

    .seatMapLink:hover {
        background-color: #2d6892;
        color: #FFF;
    }

.seatMapDisabled {
    color: #999999;
}

.showtime-trailer-link {
    display: inline;
}

.bottom-btnShowtimes {
    text-align: center;
    margin-left: 5%;
}

.showtime-type {
    vertical-align: middle;
}

.showtime {
    display: block;
    float: left;
    text-align: center;
    padding: 6px 0px 6px 0px;
    border: solid 1px #e5e5e5;
    font-size: .85714em;
    white-space: nowrap;
    width: 75px;
}

    .showtime.disabled {
        color: #999999;
        background-color: #fff;
        cursor: default;
        min-width: 115px;
    }

    .showtime.disabled-past {
        color: #999999;
        background-color: #fff;
        cursor: default;
        height: 28px !important;
    }

a.showtime {
    background-color: #fff;
    margin: 0;
    font-weight: 700;
    margin-left: 0px;
    height: 28px !important;
}

    a.showtime:hover {
        background-color: #2d6892;
        color: #fff;
    }

    a.showtime:active {
        background-color: #214c6b;
        color: #fff;
    }

    a.showtime.noclick {
        background-color: #d9d9d9;
        border-color: #b3b3b3;
        color: #666666;
        cursor: default;
        min-width: 115px;
    }

    a.showtime i {
        position: relative;
        top: 1px;
    }

span.showtime {
    background-color: #fff;
    font-weight: bold;
    color: #d9d9d9;
}

.experience-type-showtime-partial {
    min-height: 350px;
}
/**
    Usability Phase 4 - Showtimes Listing CSS
**/
.trailer-box {
    max-width: 640px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 0px !important;
    background-color: black !important;
    color: white;
}

    .trailer-box .modal-content {
        background-color: black !important;
    }

    .trailer-box .video-title {
        margin-top: -27px;
        color: #d9d9d9;
        margin-bottom: 0px;
        margin-left: 30px;
        margin-right: 30px;
    }

    .trailer-box .remodal-close {
        border: 2px solid white !important;
    }

        .trailer-box .remodal-close:after {
            color: white !important;
        }

.showtime--item {
    margin-left: 0px;
    border-top: 1px solid #d9d9d9;
    padding-top: 15px;
    padding-bottom: 8px;
}

    .showtime--item .grid__item:first-child {
        margin: 0 auto;
        text-align: center;
    }

    .showtime--item .grid__item i {
        margin: 0 auto;
    }

    .showtime--item .grid__item span {
        font-weight: bold;
    }

ul.showtime--list {
    line-height: normal;
    margin: 0px;
}

    ul.showtime--list > li {
        display: inline-block;
        height: 28px !important;
        padding-right: 4%;
        margin-bottom: 6px;
    }

    ul.showtime--list .iconV2-theatre-seat {
        font-size: 17px;
    }

@media (min-width: 925px) {
    .showtime--item .grid__item:first-child {
        width: 43%;
    }

    .showtime--item .grid__item:last-child {
        width: 57%;
    }
}

@media (max-width: 640px) {
    .bc-player-23a50a10-87ef-4acd-a8d7-11fcb9ad4f51_default {
        width: 310px;
        margin: 0 auto;
    }
}

@media (max-width: 769px) {
    .showtime--item {
        padding-top: 8px;
    }

        .showtime--item .experience-type-grid {
            padding-top: 5px;
            padding-left: 15px;
            padding-bottom: 5px;
        }

        .showtime--item .showtime-grid {
            padding-top: 5px;
            padding-left: 10px;
        }
}

@media (max-width: 481px) {
    .showtime--item:first-child {
        border-top: none;
    }
}

.movie-poster {
    display: block;
    overflow: hidden;
    position: relative;
    text-align: center;
}

    .movie-poster > i {
        font-size: 3em;
        color: #cccccc;
        position: absolute;
        text-shadow: 1px 1px #000000;
        opacity: 0.5;
        left: 50%;
        top: 50%;
        margin: -23px 0 0 -18px;
    }

    .movie-poster .overlay_mouseout,
    .movie-poster:hover .overlay_mouseover,
    .home-movie-poster .overlay_mouseout,
    .home-movie-poster:hover .overlay_mouseover {
        display: block;
    }

    .movie-poster:hover .overlay_mouseout,
    .movie-poster .overlay_mouseover,
    .home-movie-posterr:hover .overlay_mouseout,
    .home-movie-poster .overlay_mouseover {
        display: none;
    }

.poster-overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -40px 0 0 -40px;
}

.home-movie-poster {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    display: block;
}

    .home-movie-poster .dbposter {
        left: auto;
        position: relative;
        top: auto;
    }

    .home-movie-poster .poster-overlay {
        position: absolute !important;
        left: 50% !important;
        top: 50% !important;
        margin: -40px 0 0 -40px !important;
        z-index: 2;
    }

.movie-showtime-header {
    padding-left: 12px;
    padding-bottom: 12px;
}

    .movie-showtime-header .grid__item:first-child {
        margin-top: 12px;
        max-width: 130px;
    }

    .movie-showtime-header .iconV2.iconV2-theatre-seat {
        color: #2d6892;
    }

    .movie-showtime-header .movie-showtimes-details {
        padding-right: 10px;
        margin-top: 12px;
    }

    .movie-showtime-header .date-calendar {
        padding-left: 0px;
    }

.ticket-calendar.calendar-small {
    width: 100px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-right: 12px;
    background-color: #2d6892;
    padding-bottom: 3px;
}

    .ticket-calendar.calendar-small .ticket-calendar--inner {
        margin: 0 3px;
        color: #2d6892;
    }

        .ticket-calendar.calendar-small .ticket-calendar--inner > div:first-child {
            padding-top: 6px;
        }

    .ticket-calendar.calendar-small .ticket-calendar--bottom {
        background-color: #fff;
        color: #a1b8cb;
        margin: 0 4px;
    }

    .ticket-calendar.calendar-small .ticket-date {
        font-size: 51px;
        line-height: 0.9;
    }

    .ticket-calendar.calendar-small .ticket-month {
        font-size: 12px;
    }

    .ticket-calendar.calendar-small .ticket-year {
        font-size: 12px;
    }

    .ticket-calendar.calendar-small .ticket-day {
        padding: 3px 0 1px;
        font-size: 15px;
    }

    .ticket-calendar.calendar-small .ticket-time.movie-nopasses {
        text-transform: none;
    }

.btn-series {
    display: inline;
    margin-right: 5px;
}

.movie-nopasses {
    color: #c81b26;
    font-size: 11px;
    text-transform: none;
    font-weight: bold;
}

.showtime-date-mobile {
    margin-bottom: 0px;
    text-align: center;
    font-weight: bold;
    margin-left: 0px;
    margin-top: 5px;
    padding: 5px 0;
    border-top: 1px solid #e5e5e5;
}

@media (max-width: 481px) {
    .mobile-mhd-grid {
        padding-left: 0px;
    }

    .movie-header-details {
        margin-top: 5px;
        padding-left: 0px;
        /*.showtime-date {
            border-top: 1px solid @greyBorder;
            border-bottom: 1px solid @greyBorder;
            margin-bottom: 5px; //since all P tags have 10px margin bottom;
            padding-top: 5px;
            padding-bottom: 5px;
        }*/
    }

        .movie-header-details p {
            margin-bottom: 5px;
            padding-left: 10px;
        }

            .movie-header-details p:last-child {
                margin-bottom: 0px;
            }

    .movie-showtime-header {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.r-and-w-modal h5 {
    font-weight: 100;
}

    .r-and-w-modal h5 strong {
        font-weight: 700;
    }

#BarCodePromo .barcode {
    text-align: center;
}

/* April 2017 modifications to allow for showtimes structural change */

.showtime-grid ul.showtime--list > li .seatMapLink.seatMapDisabled:hover,
.showtime-grid ul.showtime--list > li .seatMapLink.seatMapDisabled:focus {
    background: #FFF;
    color: #999999;
}

.showtime-grid ul.showtime--list > li .disabled-past.after-midnight {
    padding: 2px 0;
    position: relative;
}

    .showtime-grid ul.showtime--list > li .disabled-past.after-midnight .qt-showtime-day {
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 100%;
        font-size: .8em;
        line-height: 1em;
        display: block;
        text-align: center;
    }

.showtime-grid ul.showtime--list > li a.after-midnight {
    padding: 2px 0;
    position: relative;
}

.showtime-grid ul.showtime--list > li a .qt-showtime-day {
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    font-size: .8em;
    line-height: 1em;
    display: block;
    text-align: center;
}

.showtime-grid ul.showtime--list > li .seatMapDisabled.after-midnight {
    padding: 2px 0;
    position: relative;
}

    .showtime-grid ul.showtime--list > li .seatMapDisabled.after-midnight .qt-showtime-day {
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 100%;
        font-size: .8em;
        line-height: 1em;
        display: block;
        text-align: center;
    }

.showtime-grid ul.showtime--list > li .qt-sold-out {
    background: #FFF;
    border: solid 1px #c5c5c5;
    width: 75px;
    height: 2em;
    position: relative;
    display: block;
    float: left;
    cursor: default;
    font-weight: bold;
    padding: 2px 0;
}

    .showtime-grid ul.showtime--list > li .qt-sold-out .qt-showtime {
        color: #999999;
        font-size: .85714em;
        display: block;
        text-align: center;
    }

    .showtime-grid ul.showtime--list > li .qt-sold-out .qt-sold-out-text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        color: #d0021b;
        font-size: .6em;
        line-height: 1em;
        display: block;
        text-align: center;
    }

    .showtime-grid ul.showtime--list > li .qt-sold-out .qt-showtime-day {
        display: none;
    }

.showtime-presentation-type {
    text-align: right;
}

.showtime-experience-banner {
    padding: 0px;
}

@supports (-ms-ime-align:auto) {
    .edgePadding {
        padding-left: 4.5% !important;
    }
}

@media \0screen\,screen\9 {
    /*for ie 6,7,8*/
    .iePadding {
        padding-left: 4.5% !important;
    }
}

@media screen and (min-width:0\0) and (min-resolution: .001dpcm) {
    /*for ie 9 only*/
    .iePadding {
        padding-left: 4.5% !important;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /*for ie 10, 11*/
    .iePadding {
        padding-left: 4.5% !important;
    }
}

@supports (-ms-accelerator:true) {
    /*for ie 12*/
    .iePadding {
        padding-left: 4.5% !important;
    }
}


